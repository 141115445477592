<template>
  <div>
    <CCard>
      <CCardBody>
        <div class="row justify-content-between">
          <div class="col-10">
            <div class="row mb-3">
              <button v-if="user.verificator === 0"
                class="btn btn-sm btn-primary ml-2"
                @click="addModal()"
              >
                <CIcon name="cilMedicalCross" />
                Tambah
              </button>
            </div>
          </div>
        </div>
        <div class="row justify-content-between">
          <div class="col-10">
            <div class="row mb-3">
              <label class="m-1 ml-3" for="">Search : </label>
              <input
                type="text"
                v-model="params.keyword"
                style="max-width: 200px"
                class="form-control form-control-sm mx-2"
                placeholder="Ketik disini"
              />
              <button @click="search()" class="btn btn-sm btn-success">
                Cari
              </button>
              <button
                class="btn btn-sm btn-primary ml-2"
                @click="addModalFilter()"
              >
                Filter Status
              </button>
            </div>
          </div>
          <div class="col-2">
            <div class="row">
              <div class="col-12">
                <div class="input-group input-group-sm mb-3">
                  <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01"
                      >Per Halaman</label
                    >
                  </div>
                  <select
                    class="custom-select"
                    style="max-width: 100px"
                    id="inputGroupSelect01"
                    v-model="params.row"
                    @change="getData()"
                  >
                    <!-- <option selected>Pilih...</option> -->
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="2000">2000</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="isSearching">
          <div class="col">
            <h3>
              <span class="badge bg-primary text-light text-bor my-auto">
                {{searchOn}}&nbsp;&nbsp;
                <span @click="searchOff" class="badge bg-light text-dark text-center" style="cursor: pointer">X</span>
              </span>
            </h3>
          </div>
        </div>
        <CDataTable
          class="table-striped"
          :items="computedItems"
          :fields="user.verificator === 1 ? fieldsAdmin : fields"
          sorter
        >
        <template #select="{ item }">
          <td class="py-2">
            <input 
                type="checkbox"
                @change="check(item)"
                v-model="item.select"
              />
          </td>
        </template>
          <template #action="{ item }">
            <td class="py-2">
              <CButton
                @click="view(item)"
                class="mr-2"
                color="warning"
                square
                size="sm"
                :disabled="item.status === 'EXPIRED'"
                
              >
                View
              </CButton>
              <!-- <CButton @click="hapus(item)" color="danger" square size="sm">
                Delete
              </CButton> -->
            </td>
          </template>
           <template #status="{ item }">
            <td class="py-2">
              <span v-if="item.status == 'PROSES' || item.status == 'DIEDIT' " class="badge bg-warning" style="color:white">{{ item.status }}</span>
              <span v-else-if="item.status == 'PENGAJUAN'" class="badge bg-dark" style="color:white">{{ item.status }}</span>
              <span v-else-if="item.status == 'DIKEMBALIKAN'" class="badge bg-danger" style="color:white">{{ item.status }}</span>
              <span v-else-if="item.status == 'LEGAL DRAFTING'" class="badge bg-info" style="color:white">{{ item.status }}</span>
              <span v-else-if="item.status == 'REVISI'" class="badge bg-danger" style="color:white">{{ item.status }}</span>
              <span v-else-if="item.status == 'EXPIRED'" class="badge bg-danger" style="color:white">{{ item.status }}</span>
              <span v-else class="badge bg-primary" style="color:white">{{ item.status }}</span>
              
            </td>
          </template>
          
          <template #aksi="{ item }">
            <td class="py-2">
              <span v-if="item.status == 'PROSES' || item.status == 'DIEDIT' " class="badge bg-info" style="color:white">{{ item.legal_drafter }}</span>
              <span v-else-if="item.status == 'REVISI'" class="badge bg-info" style="color:white">{{ item.legal_drafter }}</span>
              <span v-else-if="item.status == 'PENGAJUAN'" class="badge bg-info" style="color:white">{{ item.admin_verified }}</span>
              <span v-else-if="item.status == 'DIKEMBALIKAN'" class="badge bg-info" style="color:white">{{ item.user_name }}</span>
              <span v-else-if="item.status == 'LEGAL DRAFTING'" class="badge bg-info" style="color:white">{{ item.suncang_verified }}</span>
              <span v-else-if="item.status == 'APPROVED SUNCANG'" class="badge bg-info" style="color:white">{{ item.kasubag_verified }}</span>
              <span v-else-if="item.status == 'APPROVED ANALIS HUKUM'" class="badge bg-info" style="color:white">{{ item.kabag_verified }}</span>
              <span v-else-if="item.status == 'APPROVED KABAG'" class="badge bg-info" style="color:white">{{ item.asistant_verified }}</span>
              <span v-else-if="item.status == 'APPROVED ASSISTANT'" class="badge bg-info" style="color:white">{{ item.sekda_verified }}</span>
              <span v-else-if="item.status == 'APPROVED SEKDA'" class="badge bg-info" style="color:white">{{ item.bupati_verified }}</span>
              <span v-else-if="item.status == 'PENOMORAN'" class="badge bg-info" style="color:white">{{ item.asistant_verified }}</span>
              
            </td>
          </template>
          
        </CDataTable>
        <pagination
          v-if="total !== items.length"
          v-model="page"
          :records="total"
          :per-page="100"
          @paginate="pagination"
        />
      </CCardBody>
    </CCard>
    <CModal
      size="lg"
      :title="'Filter Status'"
      centered
      :color="'primary'"
      :show.sync="createModal"
    >
      <div class="row">
        <div class="col-12">
          <label for="">Status</label>
          <br />
          <div class="form-check form-check-inline" v-for="status in statuses" :key="status.status">
            <input class="form-check-input" type="checkbox" v-model="status.checked" :true-value="true" :false-value="false">
            <label class="form-check-label" for="inlineCheckbox1">{{ status.status }}</label>
          </div>
        </div>
      </div>
      <template slot="footer">
        <div>
          <button @click="submitStatus" class="btn btn-primary">
            Submit
          </button>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import * as data from "../../model/e_product";
import * as dataAdmin from "../../model/e_productAdmin";
import { uploadImage } from "@/utils/fileUpload";
import FileSaver from "file-saver";

export default {
  data() {
    return {
      file: null,
      createModal: false,
      createModalImport: false,
      fields: [],
      isUpdate: false,
      items: [],
      selectedItems: [],
      isSelectedAll: false,
      selectedAction: 0,
      page: 1,
      total: 0,
      to: 0,
      user: JSON.parse(localStorage.getItem("user")),
      params: {
        sorttype: "desc",
        sortby: "id",
        row: 100,
        page: 1,
        keyword: "",
        status: []
      },
      isSearching: false,
      searchOn: '',
      statuses: [
        {status: 'PENGAJUAN', checked: true,},
        {status: 'DIKEMBALIKAN', checked: true,},
        {status: 'DIEDIT', checked: true,},
        {status: 'PROSES', checked: true,},
        {status: 'LEGAL DRAFTING',checked: true,},
        {status: 'REVISI', checked: true,},
        {status: 'APPROVED SUNCANG', checked: true,},
        {status: 'APPROVED ANALIS HUKUM', checked: true,},
        {status: 'APPROVED KABAG',checked: true,},
        {status: 'APPROVED ASSISTANT',checked: true,},
        {status: 'APPROVED SEKDA',checked: true,},
        {status: 'EXPIRED',checked: true,},
        {status: 'PENOMORAN',checked: true,},
        {status: 'APPROVED BUPATI',checked: true,}
      ]
    };
  },
  methods: {
    view(item) {
      this.$router.push({ path: 'DocumentView',query: { id: item.id } });
    },
    submitStatus() {
      this.createModal = false;
      this.getData();
    },
    addModalFilter() {
      this.createModal = true;
    },
    search() {
      if (this.params.keyword != "") {
        this.isSearching = true;
        this.getData();
        this.searchOn = this.params.keyword;
      } else {
        this.$toast.error("Inputan tidak boleh kosong !!");
      }
    },
    searchOff(){
      this.isSearching = false;
      this.params.keyword = '';
      this.getData();
    },
    edit(item) {
      this.form = item;
      this.isUpdate = true;
      this.createModal = true;
    },
    hapus(item) {
      this.$swal.fire({
        icon: 'warning',
        html: 'Apakah yakin akan menghapus data?',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.$store
          .dispatch("document/delete", item.id)
          .then(() => {
            this.$toast.success("Berhasil menghapus data");
            this.getData();
          })
          .catch((e) => {
            this.$toast.error(e);
            loading.hide();
          });
        } 
      })
    },
    getData() {
      // filtering status disni.
      this.params.status = [];
      this.statuses.forEach(element => { 
        if (element.checked) {
          this.params.status.push(element.status)
        }
      })

      var loading = this.$loading.show();
      this.$store
        .dispatch("document/get", this.params)
        .then((resp) => {
          this.items = resp.data.data;
          this.total = resp.data.total;
          this.to = resp.data.to;
          loading.hide();
        })
        .catch((e) => {
          // this.$toast.error("gagal mengambil data  \n", e);
          loading.hide();
        });
    },
    addModal() {
      this.$store.commit('document/setDocument', {});
      this.$store.commit('document/setDocumentConsiders', []);
      this.$store.commit('document/setDocumentRemembers', []);
      this.$store.commit('document/setDocumentNotices', []);
      this.$store.commit('document/setDocumentDecisions', []);
      this.$store.commit('document/setDocumentAttachments', []);
      this.$store.commit('document/setDocumentSupports', []);
      this.$store.commit('document/setDocumentCopies', []);
      this.$router.push({ path: 'DocumentFrom' });
    },
    pagination(page) {
      this.page = page;
      this.params.page = page;
      this.getData();
    },
    getStatusByVerifikator() {
       const user = JSON.parse(localStorage.getItem('user'));
       
      if (user.verificator == 1) {
        this.statuses = [
          {status: 'PENGAJUAN', checked: true,},
          {status: 'DIKEMBALIKAN', checked: true,},
          {status: 'DIEDIT', checked: true,},
          {status: 'PROSES', checked: true,},
          {status: 'LEGAL DRAFTING',checked: true,},
          {status: 'REVISI', checked: true,},
          {status: 'APPROVED SUNCANG', checked: true,},
          {status: 'APPROVED ANALIS HUKUM', checked: true,},
          {status: 'APPROVED KABAG',checked: true,},
          {status: 'APPROVED ASSISTANT',checked: true,},
          {status: 'APPROVED SEKDA',checked: true,},
          {status: 'EXPIRED',checked: true,},
          {status: 'APPROVED BUPATI',checked: true,},
          {status: 'PENOMORAN',checked: true,}
          
        ]
      } else if (user.verificator == 2) {
        this.statuses = [
          {status: 'PENGAJUAN', checked: false,},
          {status: 'DIKEMBALIKAN', checked: false,},
          {status: 'DIEDIT', checked: true,},
          {status: 'PROSES', checked: true,},
          {status: 'LEGAL DRAFTING',checked: true,},
          {status: 'REVISI', checked: true,},
          {status: 'APPROVED SUNCANG', checked: false,},
          {status: 'APPROVED ANALIS HUKUM', checked: false,},
          {status: 'APPROVED KABAG',checked: false,},
          {status: 'APPROVED ASSISTANT',checked: false,},
          {status: 'APPROVED SEKDA',checked: false,},
          {status: 'EXPIRED',checked: true,},
          {status: 'APPROVED BUPATI',checked: false,},
          {status: 'PENOMORAN',checked: true,}
        ]
      } else if (user.verificator == 3) {
        this.statuses = [
          {status: 'PENGAJUAN', checked: false,},
          {status: 'DIKEMBALIKAN', checked: false,},
          {status: 'DIEDIT', checked: false,},
          {status: 'PROSES', checked: false,},
          {status: 'LEGAL DRAFTING',checked: true },
          {status: 'REVISI', checked: true,},
          {status: 'APPROVED SUNCANG', checked: true,},
          {status: 'APPROVED ANALIS HUKUM', checked: false,},
          {status: 'APPROVED KABAG',checked: false,},
          {status: 'APPROVED ASSISTANT',checked: false,},
          {status: 'APPROVED SEKDA',checked: false,},
          {status: 'EXPIRED',checked: true,},
          {status: 'APPROVED BUPATI',checked: false,},
          {status: 'PENOMORAN',checked: true,}
        ]
      }  else if (user.verificator == 4) {
        this.statuses = [
          {status: 'PENGAJUAN', checked: false,},
          {status: 'DIKEMBALIKAN', checked: false,},
          {status: 'DIEDIT', checked: false,},
          {status: 'PROSES', checked: false,},
          {status: 'LEGAL DRAFTING',checked: false },
          {status: 'REVISI', checked: true,},
          {status: 'APPROVED SUNCANG', checked: true,},
          {status: 'APPROVED ANALIS HUKUM', checked: true,},
          {status: 'APPROVED KABAG',checked: false },
          {status: 'APPROVED ASSISTANT',checked: false,},
          {status: 'APPROVED SEKDA',checked: false,},
          {status: 'EXPIRED',checked: true,},
          {status: 'APPROVED BUPATI',checked: false,},
          {status: 'PENOMORAN',checked: true,}
        ]
      } else if (user.verificator == 5) {
        this.statuses = [
          {status: 'PENGAJUAN', checked: false,},
          {status: 'DIKEMBALIKAN', checked: false,},
          {status: 'DIEDIT', checked: false,},
          {status: 'PROSES', checked: false,},
          {status: 'LEGAL DRAFTING',checked: false },
          {status: 'REVISI', checked: true,},
          {status: 'APPROVED SUNCANG', checked: false,},
          {status: 'APPROVED ANALIS HUKUM', checked: true,},
          {status: 'APPROVED KABAG',checked: true },
          {status: 'APPROVED ASSISTANT',checked: false,},
          {status: 'APPROVED SEKDA',checked: false,},
          {status: 'EXPIRED',checked: true,},
          {status: 'APPROVED BUPATI',checked: false,},
          {status: 'PENOMORAN',checked: true,}
        ]
      } else if (user.verificator == 6) {
        this.statuses = [
          {status: 'PENGAJUAN', checked: false,},
          {status: 'DIKEMBALIKAN', checked: false,},
          {status: 'DIEDIT', checked: false,},
          {status: 'PROSES', checked: false,},
          {status: 'LEGAL DRAFTING',checked: false },
          {status: 'REVISI', checked: false,},
          {status: 'APPROVED SUNCANG', checked: false,},
          {status: 'APPROVED ANALIS HUKUM', checked: false,},
          {status: 'APPROVED KABAG',checked: true },
          {status: 'APPROVED ASSISTANT',checked: true,},
          {status: 'APPROVED SEKDA',checked: false,},
          {status: 'EXPIRED',checked: true,},
          {status: 'APPROVED BUPATI',checked: false,},
          {status: 'PENOMORAN',checked: true,}
        ]
      } else if (user.verificator == 7) {
        this.statuses = [
          {status: 'PENGAJUAN', checked: false,},
          {status: 'DIKEMBALIKAN', checked: false,},
          {status: 'DIEDIT', checked: false,},
          {status: 'PROSES', checked: false,},
          {status: 'LEGAL DRAFTING',checked: false },
          {status: 'REVISI', checked: false,},
          {status: 'APPROVED SUNCANG', checked: false,},
          {status: 'APPROVED ANALIS HUKUM', checked: false,},
          {status: 'APPROVED KABAG',checked: false },
          {status: 'APPROVED ASSISTANT',checked: true,},
          {status: 'APPROVED SEKDA',checked: true,},
          {status: 'EXPIRED',checked: true,},
          {status: 'APPROVED BUPATI',checked: false,},
          {status: 'PENOMORAN',checked: true,}
        ]
      } else if (user.verificator == 8) {
        this.statuses = [
          {status: 'PENGAJUAN', checked: false,},
          {status: 'DIKEMBALIKAN', checked: false,},
          {status: 'DIEDIT', checked: false,},
          {status: 'PROSES', checked: false,},
          {status: 'LEGAL DRAFTING',checked: false },
          {status: 'REVISI', checked: false,},
          {status: 'APPROVED SUNCANG', checked: false,},
          {status: 'APPROVED ANALIS HUKUM', checked: false,},
          {status: 'APPROVED KABAG',checked: false },
          {status: 'APPROVED ASSISTANT',checked: false,},
          {status: 'APPROVED SEKDA',checked: true,},
          {status: 'EXPIRED',checked: true,},
          {status: 'APPROVED BUPATI',checked: true,},
          {status: 'PENOMORAN',checked: true,}
        ]
      }
    }
  },
  computed: {
    computedItems() {  
      return this.items.map((item, index) => {

        const stripHtmlTags = (html) => {
        const div = document.createElement("div");
        div.innerHTML = html;
        return div.textContent || div.innerText || "";
      };

        const plainTextTitle = stripHtmlTags(item.tittle);
        const truncatedTitle = plainTextTitle.length <= 55
          ? plainTextTitle
          : plainTextTitle.slice(0, 55) + ' ...';

        return {
          index: (this.to - this.items.length) + index+1 + '.',
          ...item,
          user_name: item.user ? item.user.full_name : '-',
          regional_apparatus: item.user ? item.user.regional_apparatus_name : '-',
          legal_drafter: item.legal_drafter ? item.legal_drafter.full_name : '-',
          admin_verified: item.admin_verified ? item.admin_verified.full_name : '-',
          suncang_verified: item.suncang_verified ? item.suncang_verified.full_name : '-',
          kasubag_verified: item.kasubag_verified ? item.kasubag_verified.full_name : '-',
          kabag_verified: item.kabag_verified ? item.kabag_verified.full_name : '-',
          asistant_verified: item.asistant_verified ? item.asistant_verified.full_name : '-',
          sekda_verified: item.sekda_verified ? item.sekda_verified.full_name : '-',
          bupati_verified: item.bupati_verified ? item.bupati_verified.full_name : '-',
          document_type: item.document_type ? item.document_type.name : '-', 
          date: this.$moment(item.date).format("Do MMMM YYYY"),
          created_at: this.$moment(item.created_at).format("Do MMMM YYYY HH:mm"),
          updated_at: this.$moment(item.updated_at).format("Do MMMM YYYY HH:mm"),
          tittle: truncatedTitle
        };
      });
    },
    statusClass(status) {
      if (status == 'PROSES') {
        return {
          'text-danger': this.error && this.error.type === 'fatal'
        }
      }
    }
  },
  mounted() {
    this.getStatusByVerifikator();
    this.getData();
  },
  created() {
    this.fields = data.fields,
    this.fieldsAdmin = dataAdmin.fieldsAdmin
  },
};
</script>